import axios from "axios";
import { AnimatePresence, motion } from "framer-motion";
import Cookies from "js-cookie";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { useEffectOnce } from "react-use";
import { setAxiosFactory, setBaseUrl } from "~/api-hooks/query/helpers";
import { getLayout } from "~/components/page";
import { WO_EMAIL_CAPTURE_COOKIE_NAME } from "~/lib/constants";
import { pageview } from "~/lib/gtag";
import { identify } from "~/lib/identify";
import { AppProvider } from "~/state-containers/app";
import { SessionProvider } from "~/state-containers/session";
import { SESSION_KEY } from "../state-containers/session";
import "../styles/globals.css";

const queryClient = new QueryClient();

setBaseUrl(process.env.NEXT_PUBLIC_API_BASE_URL + "/v1");

(() => {
  const session = Cookies.get(SESSION_KEY);
  if (!session) return;

  let instanceFactory = () =>
    axios.create({
      baseURL: process.env.NEXT_PUBLIC_API_BASE_URL + "/v1",
      headers: {
        Authorization: `Bearer ${session}`,
      },
    });

  setAxiosFactory(instanceFactory);
})();

function MyApp({ Component, pageProps }) {
  const [routeChangeActive, setRouteChangeActive] = useState(false);
  const router = useRouter();
  const Layout = getLayout(Component);

  useEffect(() => {
    const handleRouteChangeStart = () => setRouteChangeActive(true);
    const handleRouteChangeEnd = () => setRouteChangeActive(false);

    const handleRouteChange = (url) => {
      handleRouteChangeEnd();
      pageview(url);
      window?.analytics?.page?.();
      window?.scrollTo?.(0, 0);
    };

    router.events.on("routeChangeStart", handleRouteChangeStart);
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events, router]);

  useEffectOnce(() => {
    const email = Cookies.get(WO_EMAIL_CAPTURE_COOKIE_NAME);
    if (!!email) {
      identify({ email });
    }
  }, []);

  return (
    <SessionProvider>
      <AnimatePresence>
        {routeChangeActive ? (
          <motion.div
            transition={{ duration: 0.25 }}
            animate={{ opacity: [0, 1] }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-[100] flex text-white bg-neutral-700/25 backdrop-blur center"
          >
            <motion.div
              transition={{ duration: 0.25 }}
              animate={{ scale: [0, 1], opacity: [0, 1] }}
              exit={{ scale: 5, opacity: 0 }}
            >
              <motion.svg
                className="w-8 h-8 mr-3 -ml-1 text-white animate-spin"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </motion.svg>
            </motion.div>
          </motion.div>
        ) : null}
      </AnimatePresence>
      <QueryClientProvider client={queryClient}>
        <AppProvider>
          <Layout>
            <Head>
              <meta charSet="UTF-8" />
              <meta name="theme-color" content="#0e0e0e" />
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0, viewport-fit=cover"
              />
              <meta name="apple-mobile-web-app-capable" content="yes" />
              <meta
                name="apple-mobile-web-app-status-bar-style"
                content="black"
              />
            </Head>
            <Component {...pageProps} />
          </Layout>
        </AppProvider>
      </QueryClientProvider>
    </SessionProvider>
  );
}

export default MyApp;
