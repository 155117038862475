import React from "react";
import cn from "clsx";

const Noop = ({ children }) => <>{children}</>;

export const Page = ({ children, className, ...props }) => {
  return (
    <main {...props} className={cn("bg-black relative", className)}>
      {children}
    </main>
  );
};

export function getLayout(Component) {
  return Component.Layout || Noop;
}
