export const FEATURE_FLAGS = {
  BRAZE: process.env.NEXT_PUBLIC_BRAZE_ENABLED === "true",
  AUTH: process.env.NEXT_PUBLIC_FEATURE_FLAG_AUTH === "true",
  WORKOUT_CREDIT:
    process.env.NEXT_PUBLIC_FEATURE_FLAG_WORKOUT_CREDIT === "true",
};

export const COOKIE_NAME = "ab-optimize";
export const WO_EMAIL_CAPTURE_COOKIE_NAME = "WO_EMAIL_CAPTURE";
export const HOME_EXPERIMENT_ID = "JjgKz79KQaapKlnQ_piuYQ";
export const WORKOUT_EXPERIMENT_ID = "ixJsAky2Rbe7tCPeqMp6zg";

export const AUTH_REDIRECT_COOKIE_KEY = "auth-redirect-pathname";
export const SUBSCRIPTION_DEEPLINK = "SUBSCRIPTION_DEEPLINK";

export const BASE_QUIZ_VARIANT =
  process.env.NEXT_PUBLIC_BASE_QUIZ_VARIANT || "riddler_hybrid_og_v2";

export const events = {
  Web_Quiz_Started: "Match Quiz Started",
  Pre_Quiz_CTA_Clicked: "Match Quiz CTA Clicked",
  Web_Quiz_Completed: "Match Quiz Completed",
  Survey_Sign_In_With_Apple_Clicked: "Match Quiz Sign In With Apple Clicked",
  Web_Authenticated: "Match Quiz User Authenticated",
  APPSTORE_REDIRECT: "Match Quiz App Store Redirect Activated",
  Goal_Question_Answered: "Match Quiz Goal Question Answered",
  Benefit_Question_Answered: "Match Quiz Benefit Question Answered",
  Age_Question_Answered: "Match Quiz Age Question Answered",
  WO_Days_Question_Answered: "Match Quiz WO Days Question Answered",
  WO_Length_Question_Answered: "Match Quiz WO Length Question Answered",
  Equipment_Question_Answered: "Match Quiz Equipment Question Answered",
  Preferred_Styles_Question_Answered:
    "Match Quiz Preferred Styles Question Answered",
  Coach_Gender_Pref_Question_Answered:
    "Match Quiz Coach Gender Pref Question Answered",
  Web_Email_Captured: "Web Email Captured",
};

export const DEMO_FALLBACK_PORTRAITS = [
  "https://images.unsplash.com/photo-1522075469751-3a6694fb2f61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&q=80",
  "https://images.unsplash.com/photo-1542909168-82c3e7fdca5c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&q=80",
  "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&q=80",
  "https://images.unsplash.com/photo-1519699047748-de8e457a634e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&q=80",
  "https://images.unsplash.com/photo-1597223557154-721c1cecc4b0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&q=80",
  "https://images.unsplash.com/photo-1447194047554-cfe888edc98c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&q=80",
  "https://images.unsplash.com/photo-1518991669955-9c7e78ec80ca?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&q=80",
  "https://images.unsplash.com/photo-1518991669955-9c7e78ec80ca?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&q=80",
  "https://images.unsplash.com/photo-1518577915332-c2a19f149a75?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&q=80",
  "https://images.unsplash.com/photo-1485893086445-ed75865251e0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&q=80",
  "https://images.unsplash.com/photo-1504553101389-41a8f048c3ba?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&q=80",
  "https://images.unsplash.com/photo-1520451644838-906a72aa7c86?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&q=80",
];

export const daysOfWeekLong = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
