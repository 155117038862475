import Cookies from "js-cookie";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { createContainer } from "unstated-next";

export const SESSION_KEY = "joinladder.session";
export const MEMBER_ID_SESSION_KEY = "joinladder.session.member.id";

const useContainer = () => {
  const router = useRouter();
  const [sessionError, setSessionError] = useState(false);

  const session = Cookies.get(SESSION_KEY);
  const sessionMemberID = Cookies.get(MEMBER_ID_SESSION_KEY);

  const setSession = useCallback((nextSession, id = "") => {
    Cookies.set(SESSION_KEY, nextSession, { expires: 365 });
    Cookies.set(MEMBER_ID_SESSION_KEY, id, { expires: 365 });
  }, []);

  const removeQueryParam = useCallback(
    (param) => {
      const { pathname, query } = router;
      const params = new URLSearchParams(query);
      params.delete(param);
      router.replace({ pathname, query: params.toString() }, undefined);
      window.location.reload();
    },
    [router]
  );

  useEffect(() => {
    if (router?.query?.token && !Cookies.get(SESSION_KEY)) {
      Cookies.set(SESSION_KEY, router.query.token);
      removeQueryParam("token");
    }
  }, [router?.query?.token, removeQueryParam]);

  return {
    session,
    setSession,
    sessionError,
    setSessionError,
    sessionMemberID,
  };
};

const { useContainer: useSession, Provider: SessionProvider } =
  createContainer(useContainer);

export { useSession, SessionProvider };
