import React from "react";
import { createContainer } from "unstated-next";

const useContainer = () => {
  const n = React.useState(true);

  return {
    isHeroCTAInView: n[0],
    setIsHeroCTAInView: n[1],
  };
};

const { useContainer: useApp, Provider: AppProvider } =
  createContainer(useContainer);

export { useApp, AppProvider };
