export function gaHandler() {
  const dataLayer = (window.dataLayer = window.dataLayer || []);

  dataLayer.push(arguments);
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
  gaHandler("config", process.env.NEXT_PUBLIC_GA_TRACKING_ID, {
    page_path: url,
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
  gaHandler("event", action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/cookies-user-id#set_user_id
export const config = (data = {}) => {
  gaHandler("config", process.env.NEXT_PUBLIC_GA_TRACKING_ID, data);
};

export const setUserProperty = (data = {}) => {
  gaHandler("set", "user_properties", data);
};

// https://developers.google.com/optimize/devguides/experiments
export const setExperiment = (experimentId, variantId) => {
  gaHandler("set", "exp", `${experimentId}.${variantId}`);
};
